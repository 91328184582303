import type { Action, OpenDialogType } from '$/types'

export const openDialogReducer = (
  openDialog: OpenDialogType,
  action: Action,
): OpenDialogType => {
  switch (action.type) {
    case 'UPDATE_OPEN_DIALOG':
      return action.openDialog

    default:
      return openDialog
  }
}
