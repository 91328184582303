import React from 'react'
import { FormattedMessage } from 'react-intl'

import { getSubscriptionPlanName, isSubscribed } from '$/utils/subscription'
import { useDispatch, useUserState, useSubscriptionState } from '$/hooks'

import * as authAPI from '../../../api/auth'
import {
  Anchor,
  OptionsOuterContainer,
  Item,
  ContainerHead,
  Separator,
  Footer,
} from './MobileMenu.styled'
import NavigationBar from '../NavigationBar'
import { LoginButton, SignUpButton } from '../Header.styled'

const MobileMenu = (): React.ReactElement => {
  const dispatch = useDispatch()
  const subscription = useSubscriptionState()
  const user = useUserState()

  const openAuthDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({
      type: 'UPDATE_OPEN_DIALOG',
      openDialog: event.currentTarget.value,
    })
  }

  const signOut = async () => {
    await authAPI.signOut()
  }

  return (
    <OptionsOuterContainer>
      <NavigationBar />
      {isSubscribed(subscription) ? (
        <>
          <Separator />
          <Item>
            <ContainerHead>
              <FormattedMessage id="app.yourAccount" />
              <br />
              <b>{user && user!.email}</b>
            </ContainerHead>
          </Item>
          <Item>
            <FormattedMessage id="app.subscription" />
            :&nbsp;
            {getSubscriptionPlanName(subscription)}
          </Item>
          <Anchor onClick={signOut}>
            <FormattedMessage id="app.signOut" />
          </Anchor>
        </>
      ) : (
        <Footer>
          <LoginButton onClick={openAuthDialog} value="signin">
            <FormattedMessage id="app.login" />
          </LoginButton>
          <SignUpButton onClick={openAuthDialog} value="signup">
            <FormattedMessage id="app.signUp" />
          </SignUpButton>
        </Footer>
      )}
    </OptionsOuterContainer>
  )
}

export default MobileMenu
