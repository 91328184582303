import type { Action, User } from '$/types'

export const userReducer = (user: User, action: Action): User => {
  switch (action.type) {
    case 'UPDATE_USER':
      return action.user

    default:
      return user
  }
}
