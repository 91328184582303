import Firebase, { auth } from '$/components/Firebase'
import type { User } from '$/types'

const deleteOrphanAuthInstance = async (
  prevUser: User,
  nextUser: User,
): Promise<void> => {
  if (!prevUser || !nextUser) return
  if (prevUser.uid !== nextUser.uid) {
    await prevUser.delete()
  }
}

const signInWithCredential = async (
  error: any,
  onSuccess: (user: User) => void,
  onError?: (error: string) => void,
) => {
  // Only care about errors we can handle
  if (error.code === 'auth/credential-already-in-use') {
    const { currentUser } = auth

    try {
      // If credentials are in use, sign in
      const { user } = await auth.signInWithCredential(error.credential)

      onSuccess(user)
      // Now that user is signed, delete the anonymous (now orphan) instance
      await deleteOrphanAuthInstance(currentUser, user)
    } catch (reason) {
      const errorMessage = (reason as Error).toString()

      if (onError) onError(errorMessage)
    }
  }
}

export const googleSignUp = async (
  onSuccess: () => void,
  onError?: (error: string) => void,
): Promise<void> => {
  const provider = new Firebase.auth.GoogleAuthProvider()

  try {
    // Link current anonymous account with Google
    const result = await auth.currentUser?.linkWithPopup(provider)

    if (!result) throw new Error('[Google Sign Up] Unable to link with popup')
    onSuccess()
  } catch (error) {
    // We couldn't link credentials via popup. If credential is being used,
    // instead of trying to sign up, just try to sign in.
    signInWithCredential(error, onSuccess, onError)
  }
}
