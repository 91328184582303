import { auth } from '$/components/Firebase'

const EMAIL_RESET_TIMEOUT = 15000

export const sendResetPasswordEmail = async (
  email: string,
  setEmailStatus: (emailSent: string) => void,
): Promise<void> => {
  try {
    setEmailStatus('processing')
    await auth.sendPasswordResetEmail(email)
    setEmailStatus('success')
    // Reset status after 15s
    setTimeout(() => setEmailStatus(''), EMAIL_RESET_TIMEOUT)
  } catch (error) {
    setEmailStatus((error as Error).message)
  }
}
