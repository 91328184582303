import { auth } from '$/components/Firebase'

import type { Error } from '@firebase/auth-types'

interface UserData {
  email: string
  password: string
}

export const signInViaEmail = async (
  { email, password }: UserData,
  onSuccess: () => void,
  onError: (error: Error | undefined) => void,
): Promise<void> => {
  try {
    const credentials = await auth.signInWithEmailAndPassword(email, password)

    if (credentials) {
      onSuccess()
    }
  } catch (error) {
    onError(error as Error)
  }
}
