import React, { useEffect, useState } from 'react'

import Bugsnag from '$/utils/bugsnag'
import { hashForCrossPlatformTracking } from '$/utils/user'
import {
  useDispatch,
  useInitializationState,
  useMaximumNumberOfVehicles,
  useStopsState,
  useUserState,
} from '$/hooks'
import {
  initialNumberOfStopInputs,
  featuresForUsersWhoAreNotSubscribed,
  autoAnonymousLoginDelay,
} from '$/config'
import { getMaxDriversAndStops } from '$/utils/subscription'
import type { AppInitialization, Optional, Stop, Subscription } from '$/types'
import { buildFormattedAddress } from '$/utils/stops/address'

import * as RouteAPI from '../../api/route'
import * as StopAPI from '../../api/stop'
import * as AuthAPI from '../../api/auth'
import * as TeamAPI from '../../api/team'
import Firebase from '../Firebase'

import type { User } from '@firebase/auth-types'
import type {
  DocumentData,
  DocumentChange,
  DocumentSnapshot,
  QuerySnapshot,
  DocumentReference,
} from '@firebase/firestore-types'

const { unsubscribed, anonymous } = featuresForUsersWhoAreNotSubscribed

interface StopTypeCounts {
  start: number
  stop: number
  end: number
}

const StateInitializer: React.FC = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const dispatch = useDispatch()
  const stops = useStopsState()
  const user = useUserState()
  const numberOfDrivers = useMaximumNumberOfVehicles()
  let isAppInitialized = useInitializationState()

  const [routeReference, setRouteReference] =
    useState<DocumentReference | null>(null)

  useEffect(() => {
    const unsubscribeFromAuth = Firebase.auth().onAuthStateChanged(
      (authedUser) => {
        dispatch({ type: 'UPDATE_USER', user: authedUser })
      },
    )

    return () => {
      unsubscribeFromAuth()
    }
  }, [])

  useEffect(() => {
    if (!user) return
    if (!routeReference) return
    const unsubscribeFromRoute = RouteAPI.subscribe(user, (route) =>
      onRouteSubscribe(route),
    )

    const unsubscribeFromStops = StopAPI.subscribe(
      routeReference,
      (currentStops) =>
        onStopsUpdate(routeReference, currentStops, numberOfDrivers),
    )

    return () => {
      unsubscribeFromRoute
      unsubscribeFromStops
    }
  }, [routeReference, numberOfDrivers])

  useEffect(() => {
    let unsubscribeFromTeam: Promise<Firebase.Unsubscribe>

    let delayedInitUser = () => {
      setRouteReference(null)

      if (!user) {
        Bugsnag.setUser(undefined)
        AuthAPI.authenticateAnonymously()

        return
      }

      if (user.isAnonymous) {
        dispatch({
          type: 'UPDATE_SUBSCRIPTION',
          subscription: anonymous,
        })
      } else {
        dispatch({
          type: 'UPDATE_SUBSCRIPTION',
          subscription: unsubscribed,
        })

        unsubscribeFromTeam = TeamAPI.subscribe(user, onTeamUpdate)
      }

      Bugsnag.setUser(
        hashForCrossPlatformTracking(user),
        user.email ?? undefined,
        user.phoneNumber ?? undefined,
      )

      getLatestRoute(user)
    }

    setTimeout(
      () => {
        delayedInitUser()
      },
      user ? 0 : autoAnonymousLoginDelay,
    )

    return () => {
      if (unsubscribeFromTeam) unsubscribeFromTeam
      delayedInitUser = () => {}
    }
  }, [user, user?.isAnonymous])

  useEffect(() => {
    dispatch({
      type: 'RERENDER_STOPS',
      numberOfDrivers,
    })
  }, [numberOfDrivers])

  useEffect(() => {
    if (!routeReference) return
    if (!stops) return
    initializeRouteStopsIfNeeded(routeReference!, stops.size)
  }, [stops])

  const getLatestRoute = async (currentUser: User) => {
    const latestRoute = await RouteAPI.getLatest(currentUser)

    setRouteReference(latestRoute.ref)
  }

  const onRouteSubscribe = async (doc: DocumentSnapshot) => {
    dispatch({ type: 'UPDATE_ROUTE', data: doc.data(), ref: doc.ref })
    initializeRouteDependentState(doc)
  }

  const initializeRouteDependentState = async (doc: DocumentSnapshot) => {
    try {
      const routeData = doc.data()

      initializeMaximumNumberOfVehicles(routeData!)
      initializeEndStopPreference(routeData!)
    } catch (error) {
      console.error('Error getting route data.', error)
    }
  }

  const initializeMaximumNumberOfVehicles = ({
    maximumNumberOfVehicles,
  }: DocumentData) => {
    if (maximumNumberOfVehicles) {
      dispatch({
        type: 'UPDATE_MAXIMUM_NUMBER_OF_VEHICLES',
        maximumNumberOfVehicles: parseInt(maximumNumberOfVehicles, 10),
      })
    }
  }

  const initializeEndStopPreference = ({ endStopPreference }: DocumentData) => {
    if (endStopPreference) {
      dispatch({
        type: 'UPDATE_END_STOP_PREFERENCE',
        preference: endStopPreference,
      })
    }
  }

  // Every time any of a route's stops is updated,
  // rebuild stops state
  const onStopsUpdate = (
    routeRef: DocumentReference,
    newStops: QuerySnapshot,
    newNumberOfDrivers: number,
  ): void => {
    const { changes, typeCount } = parseDocChanges(newStops)

    dispatch({
      type: 'EDIT_STOPS_BATCH',
      changes,
      isAppInitialized,
      numberOfDrivers: newNumberOfDrivers,
    })

    initializeDepotStopsIfNeeded(isAppInitialized, routeRef, typeCount)
    isAppInitialized = true
  }

  const parseDocChanges = (stopsSnap: QuerySnapshot) => {
    const changes: DocumentChange[] = []
    const typeCount = { start: 0, stop: 0, end: 0 }

    stopsSnap.docChanges().forEach((snapshot) => {
      const { type } = snapshot.doc.data()

      switch (type) {
        case 'start':
          processStartStopSnapshot(snapshot)
          typeCount.start++
          break

        case 'end':
          processEndStopSnapshot(snapshot)
          typeCount.end++
          break

        case 'stop':
        default:
          changes.push(snapshot)
          typeCount.stop++
          break
      }
    })

    return { changes, typeCount }
  }

  const processStartStopSnapshot = (snapshot: DocumentChange) => {
    if (snapshot.type === 'removed') {
      dispatch({ type: 'CLEAR_START_STOP_ADDRESS' })
    } else {
      const startStop = makeStopFromSnapshot(snapshot)

      dispatch({
        type: 'UPDATE_START_STOP_ADDRESS',
        startStop,
      })
    }
  }

  const processEndStopSnapshot = (snapshot: DocumentChange) => {
    if (snapshot.type === 'removed') {
      dispatch({ type: 'CLEAR_END_STOP_ADDRESS' })
    } else {
      const endStop = makeStopFromSnapshot(snapshot)

      dispatch({ type: 'UPDATE_END_STOP_ADDRESS', endStop })
    }
  }

  const makeStopFromSnapshot = (snapshot: DocumentChange) => {
    const doc = snapshot.doc.data()

    doc.formattedAddress = buildFormattedAddress(doc as Stop)
    const { id } = snapshot.doc.ref

    return { id, doc }
  }

  const initializeDepotStopsIfNeeded = (
    isAppCurrentlyInitialized: AppInitialization,
    routeRef: DocumentReference,
    typeCount: StopTypeCounts,
  ) => {
    if (isAppCurrentlyInitialized) return

    initializeStartStopIfNeeded(routeRef, typeCount.start)
    initializeEndStopIfNeeded(routeRef, typeCount.end)

    dispatch({ type: 'UPDATE_INITIALIZATION', initialization: true })
  }

  const initializeStartStopIfNeeded = (
    routeRef: DocumentReference,
    startStopCount: number,
  ) => {
    if (startStopCount > 0) return

    const startStop = {
      id: routeRef.collection('stops').doc().id,
    }

    dispatch({ type: 'UPDATE_START_STOP_ADDRESS', startStop })
  }

  const initializeRouteStopsIfNeeded = (
    routeRef: DocumentReference,
    stopCount: number,
  ) => {
    if (!routeRef) return

    const newStops = new Map<string, Optional<Stop>>()

    for (let i = stopCount; i < initialNumberOfStopInputs; i++) {
      const { id } = routeRef!.collection('stops').doc()

      newStops.set(id, null)
    }

    if (newStops.size > 0) {
      dispatch({ type: 'ADD_STOPS_BATCH', newStops, numberOfDrivers })
    }
  }

  const initializeEndStopIfNeeded = (
    routeRef: DocumentReference,
    endStopCount: number,
  ) => {
    if (endStopCount > 0) return

    const endStop = {
      id: routeRef.collection('stops').doc().id,
    }

    dispatch({ type: 'UPDATE_END_STOP_ADDRESS', endStop })
  }

  const onTeamUpdate = (teamDoc: DocumentSnapshot, updatedUser: User): void => {
    const subscription = getSubscriptionFromTeamDataAndUser(
      teamDoc,
      updatedUser,
    )

    dispatch({ type: 'UPDATE_SUBSCRIPTION', subscription })
    dispatch({ type: 'UPDATE_TEAM', data: teamDoc.data(), ref: teamDoc.ref })
  }

  const getSubscriptionFromTeamDataAndUser = (
    teamDoc: DocumentSnapshot,
    currentUser: User,
  ): Subscription => {
    const data = teamDoc.data()

    if (!data?.subscriptionV2) {
      return currentUser.isAnonymous ? anonymous : unsubscribed
    }

    const productIds = new Map(
      Object.entries(data!.subscriptionV2.stripeProductSubscriptions || {}),
    )

    const { maxDrivers, maxStops } = getMaxDriversAndStops(user, productIds)

    const subscription = {
      validUntil: data.subscriptionV2.validUntil,
      maxDrivers,
      maxStops,
      stripeProductIds: productIds,
      stripeCustomerId: data.subscriptionV2.stripeCustomerId,
      teamId: teamDoc.id,
    }

    return subscription
  }

  return <>{children}</>
}

export default StateInitializer
