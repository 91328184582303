import styled from 'styled-components'
import tw from 'twin.macro'

import { BorderedButton, Button } from '../Button/Button.styled'

const Container = styled.header`
  ${tw`
    bg-white
    fixed
    flex
    h-18
    items-center
    justify-between
    mb-5
    px-5
    pt-0
    relative
    shadow-lg
    top-0
    w-full
    z-10
    z-20

    sm:mb-16
    sm:px-8
    sm:sticky

    print:hidden
  `}
`

const Logo = styled.div`
  background-image: url(/images/logo.svg);

  ${tw`
    bg-center
    bg-no-repeat
    cursor-pointer
    h-6
    min-w-36
    z-30
  `}
`

const Nav = styled.nav`
  ${tw`
    hidden
    sm:grid
    grid-flow-col
    grid-cols-4
    gap-10
    items-center
  `}
`

const NavLink = styled.a`
  ${tw`
    text-sm
    font-normal
    sm:font-bold
    font-heading
    cursor-pointer
  `}
`

const AuthenticationArea = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap: 10px;
`

const LoginButton = styled(BorderedButton)`
  ${tw`
    hidden
    sm:flex
    bg-white
  `}
`

const SignUpButton = styled(Button)``

export {
  Container,
  Logo,
  Nav,
  NavLink,
  AuthenticationArea,
  LoginButton,
  SignUpButton,
}
