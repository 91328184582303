import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useDispatch, useDialogState } from '$/hooks'

import Dialog from '../Dialog/Dialog'
import * as authAPI from '../../api/auth'
import { Section, Title, Footer, LinkButton } from '../Form/Auth.styled'
import { Button } from '../Button/Button.styled'
import InputWithValidation from '../Form/InputWithValidation'
import ConfirmDialog from '../Dialog/Confirm'

import type { Error } from '@firebase/auth-types'

const EmailSignIn: React.FC = () => {
  const dispatch = useDispatch()
  const dialogState = useDialogState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(undefined as Error | undefined)

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget

    if (name === 'email') setEmail(value)
    if (name === 'password') setPassword(value)
  }

  const signInViaEmail = async (email: string, password: string) => {
    await authAPI.signInViaEmail(
      { email, password },
      () => dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'none' }),
      (error) => setError(error),
    )
  }

  const forgotPassword = () => {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'forgotPassword' })
  }

  // Do not show error within dialog if error
  // outputs as a confirm box
  const errorMessage =
    error?.code === 'auth/user-not-found' ? undefined : error?.message

  return (
    <>
      {error?.code === 'auth/user-not-found' && (
        <ConfirmDialog
          title="app.emailDoestExistDialogTitle"
          text="app.emailDoestExistDialogBody"
          // Reset error on confirm
          onConfirm={() => setError(undefined)}
        />
      )}
      <Dialog open={dialogState === 'emailSignin'}>
        <Section>
          <Title>
            <FormattedMessage id="app.signIn" />
          </Title>
          <InputWithValidation
            type="email"
            value={email}
            onChange={onChangeHandler}
            placeholderMessage="app.emailAddress"
          />
          <InputWithValidation
            type="password"
            value={password}
            onChange={onChangeHandler}
            placeholderMessage="app.setPassword"
            errorMessage={errorMessage}
          />
          <Button
            type="button"
            disabled={email === '' && password === ''}
            onClick={() => signInViaEmail(email, password)}
          >
            <FormattedMessage id="app.confirm" />
          </Button>
        </Section>
        <Footer>
          <LinkButton type="button" onClick={forgotPassword}>
            <FormattedMessage id="app.forgotPassword" />
          </LinkButton>
        </Footer>
      </Dialog>
    </>
  )
}

export default EmailSignIn
