import type { Action, RouteState } from '$/types'

export const routeReducer = (route: RouteState, action: Action): RouteState => {
  switch (action.type) {
    case 'UPDATE_ROUTE': {
      const { data, ref } = action

      return { data, ref }
    }

    default:
      return route
  }
}
