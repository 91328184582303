import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useDispatch, useDialogState } from '$/hooks'

import Dialog from '../Dialog/Dialog'
import * as authAPI from '../../api/auth'
import {
  Section,
  Title,
  Footer,
  AuthButton,
  LinkButton,
} from '../Form/Auth.styled'

const SignIn: React.FC = () => {
  const dispatch = useDispatch()
  const dialogState = useDialogState()

  const signInWithGoogle = async () => {
    await authAPI.googleSignIn(() =>
      dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'none' }),
    )
  }

  const openEmailSignInDialog = () => {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'emailSignin' })
  }

  const openSignUpDialog = () => {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'signup' })
  }

  return (
    <Dialog open={dialogState === 'signin'}>
      <Section>
        <Title>
          <FormattedMessage id="app.loginAccount" />
        </Title>
        <AuthButton type="button" onClick={signInWithGoogle}>
          <img src="images/icons/google-logo.svg" className="google" alt="" />
          <FormattedMessage id="app.loginGoogle" />
        </AuthButton>
        <AuthButton
          type="button"
          value="emailSignin"
          onClick={openEmailSignInDialog}
        >
          <img src="images/icons/email-icon.svg" className="google" alt="" />
          <FormattedMessage id="app.loginEmail" />
        </AuthButton>
        <Footer>
          <FormattedMessage id="app.dontHaveAccount" />{' '}
          <LinkButton type="button" onClick={openSignUpDialog}>
            <FormattedMessage id="app.signUp" />
          </LinkButton>
        </Footer>
      </Section>
    </Dialog>
  )
}

export default SignIn
