import type { Optional, Stop, Stops } from '$/types'

const preSort = (stops: Stops): Array<[string, Optional<Stop>]> => {
  return [...stops.entries()].sort(([, stop1], [, stop2]) => {
    if (stop1 && stop2) return sortByDriver(stop1, stop2)

    return 0
  })
}

const sort = (stops: Stops): Array<[string, Optional<Stop>]> => {
  // eslint-disable-next-line complexity

  return [...stops.entries()].sort(([, stop1], [, stop2]) => {
    if (stop1 && stop2) {
      return (
        sortByLocalDriver(stop1, stop2) ||
        sortByStatus(stop1, stop2) ||
        sortByTimestamps(stop1, stop2) ||
        maybeSortByAddedTime(stop1, stop2)
      )
    }

    return 1
  })
}

// PRIVATE

const sortByDriver = (stop1: Stop, stop2: Stop) =>
  stop1.driver?.localeCompare(stop2?.driver)

const sortByLocalDriver = (stop1: Stop, stop2: Stop) =>
  stop1.localData.driverNumber - stop2.localData.driverNumber

const sortByStatus = (stop1: Stop, stop2: Stop) =>
  sortByPreviouslyDone(stop1, stop2) || sortBySkipped(stop1, stop2)

const sortByTimestamps = (stop1: Stop, stop2: Stop) =>
  sortByArrivalTime(stop1, stop2) ||
  sortByTimeDiff(stop1, stop2) ||
  sortByClickedDiff(stop1, stop2)

const sortByPreviouslyDone = (stop1: Stop, stop2: Stop) => {
  if (stop1.previouslyDone && !stop2.previouslyDone) {
    return -1
  }

  if (!stop1.previouslyDone && stop2.previouslyDone) {
    return 1
  }
}

const sortBySkipped = (
  { skipped: skipped1 }: Stop,
  { skipped: skipped2 }: Stop,
) => {
  // Pushes skipped stops to the bottom
  if (skipped1 && !skipped2) {
    return 1
  }

  if (!skipped1 && skipped2) {
    return -1
  }
}

const sortByArrivalTime = (
  { arrivalTime: arrivalTime1 }: Stop,
  { arrivalTime: arrivalTime2 }: Stop,
) => {
  if (arrivalTime1! < 0 && arrivalTime2! >= 0) {
    return 1
  }

  if (arrivalTime1! >= 0 && arrivalTime2! < 0) {
    return -1
  }
}

const sortByTimeDiff = (
  { arrivalTime: arrivalTime1, nextStopTime: nextStopTime1 }: Stop,
  { arrivalTime: arrivalTime2, nextStopTime: nextStopTime2 }: Stop,
) => {
  // Handles all scenarios around using "Make next stop" multiple times on multiple stops
  const timeDiff =
    Math.min(arrivalTime1!, nextStopTime1!) -
    Math.min(arrivalTime2!, nextStopTime2!)

  if (timeDiff !== 0) {
    return timeDiff
  }
}

const sortByClickedDiff = (stop1: Stop, stop2: Stop) => {
  const clickedDiff = stop2.nextStopClickedTime! - stop1.nextStopClickedTime!

  if (clickedDiff !== 0) {
    return clickedDiff
  }
}

const maybeSortByAddedTime = (stop1: Stop, stop2: Stop) =>
  stop1.addedTime! - stop2.addedTime! || 1

export { preSort, sort }
