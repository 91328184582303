import React from 'react'

import useDialogState from '$/hooks/useDialogState'

import SignUp from './SignUp'
import EmailSignUp from './EmailSignUp'
import SignIn from './SignIn'
import EmailSignIn from './EmailSignIn'
import PasswordReset from './PasswordReset'
import UpgradeRequired from './UpgradeRequired'
import CreateAccount from './CreateAccount'

const AuthDialogs: React.FC = () => {
  const dialogOpen = useDialogState()

  if (dialogOpen === 'none') return null

  return (
    <>
      <SignUp />
      <EmailSignUp />
      <SignIn />
      <EmailSignIn />
      <PasswordReset />
      <UpgradeRequired />
      <CreateAccount />
    </>
  )
}

export default AuthDialogs
