import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useDispatch, useDialogState } from '$/hooks'

import Dialog from '../Dialog/Dialog'
import * as authAPI from '../../api/auth'
import {
  Section,
  Title,
  Text,
  Footer,
  AuthButton,
  LinkButton,
} from '../Form/Auth.styled'

/* Similar to Sign Up but with different text copy */
const CreateAccount: React.FC = () => {
  const dispatch = useDispatch()
  const dialogState = useDialogState()

  const signUpWithGoogle = async () => {
    await authAPI.googleSignUp(() =>
      dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'none' }),
    )
  }

  const openEmailSignupDialog = () => {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'emailSignup' })
  }

  const openSignInDialog = () => {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'signin' })
  }

  return (
    <Dialog open={dialogState === 'createAccount'}>
      <Section>
        <Title>
          <FormattedMessage id="app.createAccount" />
        </Title>
        <Text>
          <FormattedMessage id="app.signInSignUpSubscribe" />
        </Text>
        <AuthButton type="button" onClick={signUpWithGoogle}>
          <img src="images/icons/google-logo.svg" className="google" alt="" />
          <FormattedMessage id="app.signUpGoogle" />
        </AuthButton>
        <AuthButton type="button" onClick={openEmailSignupDialog}>
          <img src="images/icons/email-icon.svg" className="email" alt="" />
          <FormattedMessage id="app.signUpEmail" />
        </AuthButton>
      </Section>
      <Footer>
        <FormattedMessage id="app.alreadyHaveAccount" />{' '}
        <LinkButton type="button" onClick={openSignInDialog}>
          <FormattedMessage id="app.logIn" />
        </LinkButton>
      </Footer>
    </Dialog>
  )
}

export default CreateAccount
