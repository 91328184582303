import type { Action, AppState } from '$/types'

import { routeReducer } from './route'
import { startStopReducer } from './startStop'
import { endStopReducer } from './endStop'
import { geolocationReducer } from './geolocation'
import { initializationReducer } from './initialization'
import { mapCenterReducer } from './mapCenter'
import { maximumNumberOfVehiclesReducer } from './maximumNumberOfVehicles'
import { stopsReducer } from './stops'
import { subscriptionReducer } from './subscription'
import { userReducer } from './user'
import { openDialogReducer } from './openDialog'
import { teamReducer } from './team'

const appReducer = (
  {
    endStop,
    geolocation,
    initialized,
    mapCenter,
    maximumNumberOfVehicles,
    route,
    startStop,
    stops,
    subscription,
    team,
    user,
    openDialog,
  }: AppState,
  action: Action,
): AppState => ({
  endStop: endStopReducer(endStop, action),
  geolocation: geolocationReducer(geolocation, action),
  initialized: initializationReducer(initialized, action),
  mapCenter: mapCenterReducer(mapCenter, action),
  maximumNumberOfVehicles: maximumNumberOfVehiclesReducer(
    maximumNumberOfVehicles,
    action,
  ),
  route: routeReducer(route, action),
  startStop: startStopReducer(startStop, action),
  stops: stopsReducer(stops, action),
  subscription: subscriptionReducer(subscription, action),
  team: teamReducer(team, action),
  user: userReducer(user, action),
  openDialog: openDialogReducer(openDialog, action),
})

export default appReducer
