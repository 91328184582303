import type { Action, TeamState } from '$/types'

export const teamReducer = (team: TeamState, action: Action): TeamState => {
  switch (action.type) {
    case 'UPDATE_TEAM': {
      const { data, ref } = action

      return { data, ref }
    }

    default:
      return team
  }
}
