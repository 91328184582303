import type { User } from '$/types'

import type { AxiosRequestConfig } from 'axios'

const getBearerToken = (user: User): Promise<string> => user!.getIdToken()

const getAuthorizationHeader = async (user: User) => {
  const bearerToken = await getBearerToken(user)

  return { Authorization: `Bearer ${bearerToken}` }
}

export const getDefaultConfig = async (
  user: User,
): Promise<AxiosRequestConfig> => ({
  headers: await getAuthorizationHeader(user),
})
