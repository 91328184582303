import type { Action, GeoCoords } from '$/types'

export const geolocationReducer = (
  coords: GeoCoords | undefined,
  action: Action,
): GeoCoords | undefined => {
  switch (action.type) {
    case 'UPDATE_GEOLOCATION_POSITION':
      return { latitude: action.latitude, longitude: action.longitude }

    default:
      return coords
  }
}
