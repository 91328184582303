import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  useDispatch,
  useDialogState,
  useUserState,
  useSubscriptionState,
} from '$/hooks'
import { featuresForUsersWhoAreNotSubscribed } from '$/config'

import Dialog from '../Dialog/Dialog'
import * as authAPI from '../../api/auth'
import {
  Section,
  Title,
  BulletList,
  Footer,
  AuthButton,
  LinkButton,
} from '../Form/Auth.styled'

const { anonymous, unsubscribed } = featuresForUsersWhoAreNotSubscribed

const SignUp: React.FC = () => {
  const dispatch = useDispatch()
  const dialogState = useDialogState()
  const user = useUserState()
  const subscription = useSubscriptionState()

  const getStringValues = (readValue: 'maxDrivers' | 'maxStops') => {
    if (user?.isAnonymous) return anonymous[readValue].toString()
    if (!subscription) return unsubscribed[readValue].toString()
  }

  const signUpWithGoogle = async () => {
    await authAPI.googleSignUp(() =>
      dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'none' }),
    )
  }

  const openEmailSignupDialog = () => {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'emailSignup' })
  }

  const openSignInDialog = () => {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'signin' })
  }

  return (
    <Dialog open={dialogState === 'signup'}>
      <Section>
        <Title>
          <FormattedMessage id="app.createAccount" />
        </Title>
        <BulletList>
          <li>
            <FormattedMessage
              id="app.createAccountBullet1"
              values={{
                stopsNumber: getStringValues('maxStops'),
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id="app.createAccountBullet2"
              values={{
                driversNumber: getStringValues('maxDrivers'),
              }}
            />
          </li>
        </BulletList>
        <AuthButton type="button" onClick={signUpWithGoogle}>
          <img src="images/icons/google-logo.svg" alt="" className="google" />
          <FormattedMessage id="app.signUpGoogle" />
        </AuthButton>
        <AuthButton type="button" onClick={openEmailSignupDialog}>
          <img src="images/icons/email-icon.svg" alt="" className="email" />
          <FormattedMessage id="app.signUpEmail" />
        </AuthButton>
      </Section>
      <Footer>
        <FormattedMessage id="app.alreadyHaveAccount" />{' '}
        <LinkButton type="button" onClick={openSignInDialog}>
          <FormattedMessage id="app.logIn" />
        </LinkButton>
      </Footer>
    </Dialog>
  )
}

export default SignUp
