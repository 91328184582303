import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: '6b68bad94bda1d00e394a960a2c47b57',
  plugins: [new BugsnagPluginReact(React)],
})

export default Bugsnag
