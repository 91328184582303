import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useDispatch, useDialogState } from '$/hooks'

import Dialog from '../Dialog/Dialog'
import * as authAPI from '../../api/auth'
import {
  Section,
  Title,
  Text,
  DialogMessage,
  SuccessIcon,
} from '../Form/Auth.styled'
import { Button } from '../Button/Button.styled'
import InputWithValidation from '../Form/InputWithValidation'
import LoadingIcon from '../Icons/Loading'

const PasswordReset: React.FC = () => {
  const dispatch = useDispatch()
  const dialogState = useDialogState()
  const [email, setEmail] = useState('')
  const [emailStatus, setEmailStatus] = useState('')

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value)
  }

  const getSendButtonText = (emailStatus: string) =>
    emailStatus === 'processing' ? 'app.sending' : 'app.sendPasswordResetLink'

  const sendResetPasswordEmail = () => {
    // Clean up first
    setEmailStatus('')
    authAPI.sendResetPasswordEmail(email, setEmailStatus)
  }

  const closeDialog = () => {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'none' })
  }

  const errorMessage = (message: string) =>
    ['', 'processing', 'success'].includes(message) ? '' : message

  return (
    <Dialog open={dialogState === 'forgotPassword'}>
      <Section>
        <Title>
          <FormattedMessage id="app.forgotPassword" />
        </Title>
        <Text>
          <FormattedMessage id="app.forgotPasswordInfo" />
        </Text>
        <InputWithValidation
          type="email"
          value={email}
          onChange={onChangeHandler}
          placeholderMessage="app.emailAddress"
          errorMessage={errorMessage(emailStatus)}
        />
        {emailStatus === 'success' ? (
          <>
            <DialogMessage>
              <SuccessIcon />
              <FormattedMessage id="app.emailSent" />
            </DialogMessage>
            <Button type="button" onClick={closeDialog}>
              <FormattedMessage id="app.retryLogin" />
            </Button>
          </>
        ) : (
          <Button
            type="button"
            disabled={email === ''}
            onClick={sendResetPasswordEmail}
          >
            {emailStatus === 'processing' && <LoadingIcon />}
            <FormattedMessage id={getSendButtonText(emailStatus)} />
          </Button>
        )}
      </Section>
    </Dialog>
  )
}

export default PasswordReset
