import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'

import { Nav, NavLink } from './Header.styled'

const NavigationBar: React.FC = () => {
  const router = useRouter()

  const handleClickOnAbout = () => {
    window.location.pathname === '/'
      ? document!
          .getElementById('about')!
          .scrollIntoView({ behavior: 'smooth' })
      : router.push('/#about')
  }

  return (
    <Nav>
      <Link href="/">
        <NavLink>
          <FormattedMessage id="app.home" />
        </NavLink>
      </Link>
      <NavLink onClick={handleClickOnAbout}>
        <FormattedMessage id="app.about" />
      </NavLink>
      <Link href="/pricing" passHref>
        <NavLink>
          <FormattedMessage id="app.pricing" />
        </NavLink>
      </Link>
    </Nav>
  )
}

export default NavigationBar
