import { auth } from '$/components/Firebase'
import type { User } from '$/types'

import type { Error } from '@firebase/auth-types'

interface UserData {
  email: string
  password: string
}

export const createUserViaEmail = async (
  { email, password }: UserData,
  onSuccess: (user: User) => void,
  onError: (error: Error | undefined) => void,
): Promise<void> => {
  try {
    const registeredUser = await auth.createUserWithEmailAndPassword(
      email,
      password,
    )

    const { currentUser } = auth

    if (currentUser && registeredUser.credential) {
      // Re-use anonymous credential
      const { user } = await currentUser.linkWithCredential(
        registeredUser.credential,
      )

      onSuccess(user)
    }

    onSuccess(registeredUser.user)
  } catch (error) {
    onError(error as Error)
    console.error('[Email Sign Up] Error upgrading anonymous account:', error)
  }
}
