import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useSubscriptionState, useDialogState, useStopsState } from '$/hooks'
import { isMaxStopsLimitReached } from '$/utils/subscription'

import Dialog from '../Dialog/Dialog'
import { Section, Title, Text, Footer } from '../Form/Auth.styled'
import { ButtonLike } from '../Button/Button.styled'

const UpgradeRequired: React.FC = () => {
  const dialogState = useDialogState()
  const subscription = useSubscriptionState()
  const stops = useStopsState()

  const getStringValues = (readValue: 'maxDrivers' | 'maxStops') => {
    return subscription[readValue].toString()
  }

  // When user tries to add more stops than allowed
  const getMessageTitleFromStopsLimit = (): JSX.Element => {
    return (
      <FormattedMessage
        id="app.stopsLimit"
        values={{
          stopsNumber: getStringValues('maxStops'),
        }}
      />
    )
  }

  // When user tries to add more drivers than allowed
  const getMessageTitleFromDriversLimit = (): JSX.Element => {
    return (
      <FormattedMessage
        id="app.driversLimit"
        values={{
          driversNumber: getStringValues('maxDrivers'),
        }}
      />
    )
  }

  const getDialogBodyMessageID = (): JSX.Element => {
    return (
      <FormattedMessage
        id="app.planLimits"
        values={{
          stopsNumber: getStringValues('maxStops'),
          driversNumber: getStringValues('maxDrivers'),
        }}
      />
    )
  }

  const getDialogTitleMessageID = (): JSX.Element => {
    const stopLimit = isMaxStopsLimitReached(stops, subscription)

    if (stopLimit) {
      return getMessageTitleFromStopsLimit()
    }

    return getMessageTitleFromDriversLimit()
  }

  return (
    <Dialog open={dialogState === 'upgradeRequired'}>
      <Section>
        <Title>{getDialogTitleMessageID()}</Title>
        <Text>{getDialogBodyMessageID()}</Text>
      </Section>
      <Footer>
        <ButtonLike href="/pricing">
          <FormattedMessage id="app.viewPricing" />
        </ButtonLike>
      </Footer>
    </Dialog>
  )
}

export default UpgradeRequired
