import type { Action } from '$/types'

export const initializationReducer = (
  initialized: boolean,
  action: Action,
): boolean => {
  switch (action.type) {
    case 'UPDATE_INITIALIZATION':
      return action.initialization

    default:
      return initialized
  }
}
