import React from 'react'
import Head from 'next/head'
import whyDidYouRender from '@welldone-software/why-did-you-render'

import Bugsnag from '$/utils/bugsnag'
import GlobalStyles from '$/theme/globals'
import { isDev } from '$/config'
import '$/css/tailwind.css'
import '@reach/combobox/styles.css'
import Firebase, { FirebaseContext } from '$/components/Firebase'
import Layout from '$/components/Layout/Layout'
import AmplitudeWrapper from '$/components/AmplitudeWrapper/AmplitudeWrapper'
import StateWrapper from '$/components/wrapper/StateWrapper'
import IntlWrapper from '$/components/wrapper/IntlWrapper'
import StateInitializer from '$/components/StateInitializer/StateInitializer'

import type { AppProps } from 'next/app'

if (typeof window !== 'undefined' && isDev) {
  whyDidYouRender(React)
}

const ErrorBoundary = Bugsnag!.getPlugin('react')!.createErrorBoundary(React)

const MyApp: React.FC<AppProps> = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <title>Speedy Route - The Delivery Route Planner</title>
        <meta
          name="description"
          content="Fast quick optimized driving directions delivery route planning software website for multiple locations stops and single or multiple delivery vehicles Find the best route with Speedy Route"
        />
        <meta name="author" content="Speedy Route, Inc." />
        <meta
          name="google-site-verification"
          content="sBtQ_caZdjnFGI_75r-Tk-GHDzzLaR2Ny2t0LFqekMw"
        />
        <meta
          name="p:domain_verify"
          content="f20811d631ec0a42c9dea7cb60483184"
        />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta property="twitter:account_id" content="4503599630247741" />
      </Head>
      <ErrorBoundary>
        <FirebaseContext.Provider value={Firebase}>
          <IntlWrapper>
            <StateWrapper>
              <GlobalStyles />
              <Layout>
                <StateInitializer>
                  <AmplitudeWrapper>
                    <Component {...pageProps} />
                  </AmplitudeWrapper>
                </StateInitializer>
              </Layout>
            </StateWrapper>
          </IntlWrapper>
        </FirebaseContext.Provider>
      </ErrorBoundary>
    </>
  )
}

export default MyApp
