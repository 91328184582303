import Firebase, { auth } from '$/components/Firebase'

export const googleSignIn = async (
  onSuccess: () => void,
  onError?: (error: string) => void,
): Promise<void> => {
  const provider = new Firebase.auth.GoogleAuthProvider()

  try {
    await auth.signInWithPopup(provider)
    onSuccess()
  } catch (error) {
    const errorMessage = (error as Error).toString()

    if (onError) onError(errorMessage)
  }
}
