import React from 'react'

export const MapPinSvg = (): React.ReactElement => (
  <svg
    className="flex-shrink-0 h-6 w-6 text-sr-500"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M15 9.5C15 11.1569 13.6569 12.5 12 12.5C10.3431 12.5 9 11.1569 9 9.5C9 7.84315 10.3431 6.5 12 6.5C13.6569 6.5 15 7.84315 15 9.5Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 9.15385C18.5 5.6085 15.6785 2.75 12.0973 2.75C8.45604 2.75 5.5 5.66777 5.5 9.15385C5.5 11.8996 6.51447 13.3609 7.96498 14.8464C8.25513 15.1435 8.58663 15.4585 8.94254 15.7966C9.89508 16.7016 11.0225 17.7727 12 19.1132C12.9775 17.7727 14.1049 16.7016 15.0575 15.7966C15.4134 15.4585 15.7449 15.1435 16.035 14.8464C17.4855 13.3609 18.5 11.8996 18.5 9.15385ZM13.1437 20.9833C14.0428 19.5366 15.1857 18.4459 16.3011 17.3816C18.4518 15.3293 20.5 13.3747 20.5 9.15385C20.5 4.51253 16.7917 0.75 12.0973 0.75C7.40283 0.75 3.5 4.51253 3.5 9.15385C3.5 13.3747 5.54822 15.3293 7.6989 17.3816C8.81427 18.4459 9.95719 19.5366 10.8563 20.9833C10.8575 20.9852 10.8586 20.9871 10.8598 20.989C10.9066 21.0644 10.9527 21.1407 10.9982 21.2181C11.1422 21.4634 11.2792 21.7189 11.4079 21.9861C11.6216 22.4296 12.3784 22.4296 12.5921 21.9861C12.7208 21.7189 12.8578 21.4634 13.0018 21.2181C13.0473 21.1407 13.0934 21.0644 13.1402 20.989C13.1414 20.9871 13.1425 20.9852 13.1437 20.9833Z"
    />
  </svg>
)
