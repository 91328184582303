import { firestore } from '$/components/Firebase'

import * as UserAPI from './user'

import type { DocumentSnapshot } from '@firebase/firestore-types'
import type { User } from '@firebase/auth-types'

export const create = async (user: User): Promise<void> => {
  const teamDoc = firestore.collection('teams').doc()

  await teamDoc.set({ platforms: ['speedyroute'] })
  await UserAPI.update(user, { teamId: teamDoc.id })
}

export const subscribe = async (
  user: User,
  callback: (snapshot: DocumentSnapshot, user: User) => void,
): Promise<() => void> => {
  const userDoc = await UserAPI.get(user.uid)

  if (!userDoc?.exists) {
    if (!user.email) {
      // Attempt to re-get email address for Logged in Firebase user
      user.reload()

      await new Promise((resolve) => setTimeout(resolve, 1000))

      return subscribe(user, callback)
    }

    await UserAPI.create(user)

    return subscribe(user, callback)
  }

  const teamId = userDoc.data()?.teamId

  if (!teamId) {
    await create(user)

    return subscribe(user, callback)
  }

  return firestore
    .collection('teams')
    .doc(teamId)
    .onSnapshot((snapshot) => callback(snapshot, user))
}
