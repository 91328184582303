/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { Select } from '../Form/Form.styled'
import { Context } from '../wrapper/IntlWrapper'

const LanguageSwitch: React.FC = () => {
  // Prevent rendering this component during SSR phase
  // since we need window access to read from localStorage
  if (typeof window === 'undefined') return <></>

  const options = [
    { value: 'en', label: useIntl().formatMessage({ id: 'locale.en' }) },
    { value: 'pt-br', label: useIntl().formatMessage({ id: 'locale.pt_br' }) },
  ]

  const context = useContext(Context)
  const handleOnChange = context.selectLanguage

  return (
    <Select value={context.locale} onChange={handleOnChange}>
      {options.map((option, index) => (
        <option key={`option-${index}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

export default LanguageSwitch
