import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'

import Portuguese from '../../../public/static/locales/pt-br.json'
import English from '../../../public/static/locales/en.json'

const keyName = 'speedyroute-locale-storage'

type LocaleStorageType = 'en' | 'pt-br'

export const getLocaleFromLocalStorage = (): LocaleStorageType => {
  // At startup time we need window access to perform localStorage checks
  if (typeof window === 'undefined') return 'en'

  const data: string | null = window.localStorage.getItem(keyName)
  let storedState: LocaleStorageType = 'en'

  if (data) {
    try {
      storedState = JSON.parse(data)
    } catch (e) {
      console.error('[IntlWrapper] Could not parse local storage data: ', e)
    }
  }

  return storedState
}

export const setLocaleToLocalStorage = (data: LocaleStorageType): void => {
  window.localStorage.setItem(keyName, JSON.stringify(data))
}

const getCurrentLanguageFile = (currentLocale: string) => {
  switch (currentLocale) {
    case 'pt-br':
      return Portuguese

    default:
      return English
  }
}

const IntlWrapper: React.FC = ({ children }) => {
  // Locale (en, pt, fr, etc) support
  const currentStoredLocale = getLocaleFromLocalStorage()
  const [locale, setLocaleToState] = useState(currentStoredLocale)

  // Language (JSON files w/ locale data) support
  const currentLanguageFile = getCurrentLanguageFile(currentStoredLocale)
  const [messages, setMessagesToState] = useState(currentLanguageFile)

  const selectLanguage = (event?: React.ChangeEvent<HTMLSelectElement>) => {
    if (!event) return

    const newLocale = event.target.value as LocaleStorageType

    if (newLocale === 'pt-br') {
      setMessagesToState(Portuguese)
    } else {
      setMessagesToState(English)
    }

    setLocaleToState(newLocale)
    setLocaleToLocalStorage(newLocale)
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export const Context = React.createContext({
  locale: getLocaleFromLocalStorage(),
  selectLanguage: () => {},
})

IntlWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default IntlWrapper
