import type { Action, MaximumNumberOfVehicles } from '$/types'

export const maximumNumberOfVehiclesReducer = (
  maximumNumberOfVehicles: MaximumNumberOfVehicles,
  action: Action,
): MaximumNumberOfVehicles => {
  switch (action.type) {
    case 'UPDATE_MAXIMUM_NUMBER_OF_VEHICLES': {
      return action.maximumNumberOfVehicles
    }

    default:
      return maximumNumberOfVehicles
  }
}
