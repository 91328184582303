import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import { StateContext } from '$/contexts'
import { initialState } from '$/config'

import appReducer from '../../reducers'

const StateWrapper: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState)

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  )
}

StateWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StateWrapper
