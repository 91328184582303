import type { Action, GeoCoords } from '$/types'

export const mapCenterReducer = (
  mapCenter: GeoCoords,
  action: Action,
): GeoCoords => {
  switch (action.type) {
    case 'UPDATE_MAP_CENTER': {
      const { latitude, longitude } = action

      return { latitude, longitude }
    }

    default:
      return mapCenter
  }
}
