import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  useDispatch,
  useDialogState,
  useUserState,
  useSubscriptionState,
} from '$/hooks'
import { featuresForUsersWhoAreNotSubscribed } from '$/config'

import Dialog from '../Dialog/Dialog'
import InputWithValidation from '../Form/InputWithValidation'
import * as authAPI from '../../api/auth'
import {
  Section,
  Title,
  BulletList,
  Footer,
  LinkButton,
} from '../Form/Auth.styled'
import { Button } from '../Button/Button.styled'
import ConfirmDialog from '../Dialog/Confirm'

import type { Error } from '@firebase/auth-types'

const { anonymous, unsubscribed } = featuresForUsersWhoAreNotSubscribed

const EmailSignUp: React.FC = () => {
  const dispatch = useDispatch()
  const dialogState = useDialogState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(undefined as Error | undefined)
  const user = useUserState()
  const subscription = useSubscriptionState()

  const getStringValues = (readValue: 'maxDrivers' | 'maxStops') => {
    if (user?.isAnonymous) return anonymous[readValue].toString()
    if (!subscription) return unsubscribed[readValue].toString()
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget

    if (name === 'email') setEmail(value)
    if (name === 'password') setPassword(value)
  }

  const createUserViaEmail = async (email: string, password: string) => {
    await authAPI.createUserViaEmail(
      { email, password },
      () => dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'none' }),
      (error) => setError(error),
    )
  }

  const openSignInDialog = () => {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'signin' })
  }

  // Do not show error within dialog if error
  // outputs as a confirm box
  const errorMessage =
    error?.code === 'auth/user-not-found' ? undefined : error?.message

  return (
    <>
      {error?.code === 'auth/email-already-in-use' && (
        <ConfirmDialog
          title="app.existingUserAccountDialogTitle"
          text="app.existingUserAccountDialogBody"
          // Reset error on confirm
          onConfirm={() => setError(undefined)}
        />
      )}
      <Dialog open={dialogState === 'emailSignup'}>
        <Section>
          <Title>
            <FormattedMessage id="app.createAccount" />
          </Title>
          <BulletList>
            <li>
              <FormattedMessage
                id="app.createAccountBullet1"
                values={{
                  stopsNumber: getStringValues('maxStops'),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="app.createAccountBullet2"
                values={{
                  driversNumber: getStringValues('maxDrivers'),
                }}
              />
            </li>
          </BulletList>
          <InputWithValidation
            type="email"
            value={email}
            onChange={onChangeHandler}
            placeholderMessage="app.emailAddress"
          />
          <InputWithValidation
            value={password}
            type="password"
            onChange={onChangeHandler}
            placeholderMessage="app.setPassword"
            errorMessage={errorMessage}
          />
          <Button
            disabled={email === '' && password === ''}
            type="button"
            onClick={() => createUserViaEmail(email, password)}
          >
            <FormattedMessage id="app.confirm" />
          </Button>
        </Section>
        <Footer>
          <FormattedMessage id="app.alreadyHaveAccount" />{' '}
          <LinkButton type="button" onClick={openSignInDialog}>
            <FormattedMessage id="app.signIn" />
          </LinkButton>
        </Footer>
      </Dialog>
    </>
  )
}

export default EmailSignUp
