import { auth } from '$/components/Firebase'

export const authenticateAnonymously = async (): Promise<void> => {
  if (!auth.currentUser) {
    try {
      await auth.signInAnonymously()
    } catch (error) {
      console.error({ error })
    }
  }
}
