import React, { useEffect } from 'react'

import { useUserState } from '$/hooks'
import { setUserId } from '$/utils/amplitude'
import { hashForCrossPlatformTracking } from '$/utils/user'

const AmplitudeWrapper: React.FC = ({ children }) => {
  const user = useUserState()

  useEffect(() => {
    const doSetUserId = async () => {
      const userId = user?.uid ? hashForCrossPlatformTracking(user!) : null

      setUserId(userId)
    }

    user?.uid && doSetUserId()
  }, [user])

  return <>{children}</>
}

export default AmplitudeWrapper
