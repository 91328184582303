import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { getSubscriptionPlanName, isSubscribed } from '$/utils/subscription'
import { useOnClickOutside, useUserState, useSubscriptionState } from '$/hooks'

import * as authAPI from '../../../api/auth'
import {
  Anchor,
  Button,
  ButtonContainer,
  Container,
  OptionsInnerContainer,
  OptionsOuterContainer,
  Item,
  ContainerHead,
  Separator,
  UserEmail,
} from './Dropdown.styled'
import { ChevronSvg } from '../../shared/svgs'
import NavigationBar from '../NavigationBar'

const HeaderDropdown = (): React.ReactElement => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>
  const subscription = useSubscriptionState()
  const user = useUserState()

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref, () => setIsOpen(false))

  const handleToggleDropdown = () => setIsOpen(!isOpen)

  const signOut = async () => {
    await authAPI.signOut()
  }

  return (
    <Container ref={ref}>
      <div>
        <ButtonContainer onClick={handleToggleDropdown}>
          <Button aria-haspopup="true" aria-expanded="true">
            <UserEmail>{user!.email}</UserEmail>
            <ChevronSvg />
          </Button>
        </ButtonContainer>
      </div>

      {isOpen && (
        <OptionsOuterContainer>
          <OptionsInnerContainer>
            <NavigationBar />
            <Separator />
            <Item>
              <ContainerHead>
                <FormattedMessage id="app.yourAccount" />
                <br />
                <b>{user!.email}</b>
              </ContainerHead>
            </Item>
            {isSubscribed(subscription) && (
              <Item>
                <FormattedMessage id="app.subscription" />
                :&nbsp;
                {getSubscriptionPlanName(subscription)}
              </Item>
            )}
            <Anchor onClick={signOut}>
              <FormattedMessage id="app.signOut" />
            </Anchor>
          </OptionsInnerContainer>
        </OptionsOuterContainer>
      )}
    </Container>
  )
}

export default HeaderDropdown
