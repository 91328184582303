import styled from 'styled-components'
import tw from 'twin.macro'

import { BorderedButton } from '../../Button/Button.styled'

export const Anchor = styled.a.attrs({ role: 'menuItem' })`
  ${tw`
    text-red-600
    block
    cursor-pointer
    text-sm
    leading-5
    px-5
    py-3
    hover:bg-gray-100
    focus:outline-none
    focus:bg-gray-100
    focus:text-gray-900
  `}
`

export const MenuButton = styled(BorderedButton)`
  ${tw`
    w-8
    h-10
    z-30
    sm:hidden
    bg-no-repeat
    bg-center
  `}
  background-image: url(/images/icons/menu-icon.svg);
`

export const CloseButton = styled(BorderedButton)`
  ${tw`
    w-8
    h-10
    z-30
    border-white
    bg-no-repeat
    bg-center
    block

    sm:hidden
  `}
  background-image: url(/images/icons/close-icon.svg);
`

export const ContainerHead = styled.div`
  ${tw`
    relative
    inline-block
    text-left
  `}

  & b {
    ${tw`
      font-bold
      text-lg
    `}
  }
`

export const OptionsOuterContainer = styled.div.attrs({
  'aria-orientation': 'vertical',
  'aria-labelledby': 'options-menu',
  role: 'menu',
})`
  ${tw`
    origin-top-right
    fixed
    top-0
    right-0
    bg-white
    font-semibold
    overflow-hidden
    pt-16
    w-full
    z-20
    flex
    flex-col
    justify-between
    shadow-lg

    sm:hidden
  `}

  nav {
    ${tw`
      grid
      grid-cols-1
      grid-rows-4
      gap-5
      mx-5
      my-8
    `}
  }
`

export const Footer = styled.footer`
  ${tw`
    p-5
    grid
    grid-cols-3
    gap-4
    h-20
    items-center
    bg-sr-50
  `}

  > button {
    ${tw`
      flex
    `}
  }

  button:first-child {
    ${tw`
      col-start-2
    `}
  }
`

export const Item = styled.div`
  ${tw`
    block
    font-normal
    py-3
    px-5
    text-sm
    leading-5
    text-gray-700
  `}
`

export const Separator = styled.div`
  ${tw`
    border-t
    border-gray-300
    mb-4
  `}
`
