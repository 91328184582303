import React, { useState } from 'react'
import Link from 'next/link'
import { FormattedMessage } from 'react-intl'

import { testLocale } from '$/config'
import { useDispatch, useUserState } from '$/hooks'

import {
  Container,
  Logo,
  AuthenticationArea,
  LoginButton,
  SignUpButton,
} from './Header.styled'
import NavigationBar from './NavigationBar'
import HeaderDropdown from './Dropdown/Dropdown'
import MobileMenu from './MobileMenu/MobileMenu'
import { MenuButton, CloseButton } from './MobileMenu/MobileMenu.styled'
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch'
import AuthDialogs from '../Authentication/AuthDialogs'

const Header: React.FC = () => {
  const dispatch = useDispatch()
  const user = useUserState()
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleMobileMenu = () => setIsOpen(!isOpen)

  const openAuthDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({
      type: 'UPDATE_OPEN_DIALOG',
      openDialog: event.currentTarget.value,
    })
  }

  return (
    <>
      <AuthDialogs />
      <Container>
        <Link href="/" passHref>
          <Logo />
        </Link>
        {testLocale && <LanguageSwitch />}
        <NavigationBar />
        {user && !user.isAnonymous ? (
          <HeaderDropdown />
        ) : (
          <AuthenticationArea>
            <LoginButton onClick={openAuthDialog} value="signin">
              <FormattedMessage id="app.login" />
            </LoginButton>
            <SignUpButton onClick={openAuthDialog} value="signup">
              <FormattedMessage id="app.signUp" />
            </SignUpButton>
            {isOpen ? (
              <CloseButton onClick={handleToggleMobileMenu} />
            ) : (
              <MenuButton onClick={handleToggleMobileMenu} />
            )}
          </AuthenticationArea>
        )}
        {
          // Authenticated users see the expanded dropdown version
          isOpen && user?.isAnonymous && <MobileMenu />
        }
      </Container>
    </>
  )
}

export default Header
