import styled from 'styled-components'
import tw from 'twin.macro'

export const Anchor = styled.a.attrs({ role: 'menuItem' })`
  ${tw`
    text-red-600
    block
    cursor-pointer
    text-sm
    leading-5
    px-5
    py-3
    sm:p-3
    hover:bg-gray-100
    focus:outline-none
    focus:bg-gray-100
    focus:text-gray-900
  `}
`

export const Button = styled.button`
  ${tw`
    inline-flex
    justify-center
    w-full
    rounded-md
    border
    border-gray-300
    px-4
    py-2
    bg-white
    text-sm
    leading-5
    font-medium
    text-gray-700
    hover:text-gray-500
    focus:outline-none
    focus:border-blue-300
    focus:ring-blue-500
    active:outline-none
    active:bg-coolGray-50
    active:text-gray-800
    transition
    ease-in-out
    duration-150
  `}
`

export const ButtonContainer = styled.span`
  ${tw`
    rounded-md
    shadow-sm
  `}
`
export const Container = styled.div`
  ${tw`
    sm:relative
    inline-block
    text-left
    z-30
    w-40
    sm:w-auto
  `}
`

export const ContainerHead = styled.div`
  ${tw`
    relative
    inline-block
    text-left
  `}

  & b {
    ${tw`
      font-bold
      text-lg
    `}
  }
`

export const OptionsInnerContainer = styled.div`
  ${tw`
    font-semibold
    overflow-hidden
    rounded-md
    bg-white
    sm:p-3
    pb-4
    sm:pb-3
    shadow-lg
  `}

  nav {
    ${tw`
      sm:hidden
      grid
      grid-cols-1
      grid-rows-4
      gap-5
      mx-5
      my-8
    `}
  }
`

export const OptionsOuterContainer = styled.div.attrs({
  'aria-orientation': 'vertical',
  'aria-labelledby': 'options-menu',
  role: 'menu',
})`
  ${tw`
    origin-top-right
    absolute
    right-0
    mt-2
    w-full
    sm:w-96
    rounded-md
    sm:shadow-lg
  `}
`

export const Item = styled.div`
  ${tw`
    block
    font-normal
    px-5
    py-3
    text-sm
    leading-5
    text-gray-700
  `}

  &:first-child {
    ${tw`
      mt-7
    `}
  }
`

export const Separator = styled.div`
  ${tw`
    border-t
    border-gray-300
    mb-4
    sm:hidden
  `}
`

export const UserEmail = styled.span`
  ${tw`
    truncate
  `}
`
