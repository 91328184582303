import type { Action, StartStop } from '$/types'

export const startStopReducer = (
  startStop: StartStop,
  action: Action,
): StartStop => {
  switch (action.type) {
    case 'UPDATE_START_STOP_ADDRESS': {
      return {
        ...startStop,
        ...action.startStop,
      }
    }

    case 'CLEAR_START_STOP_ADDRESS': {
      return {
        id: startStop?.id,
      }
    }

    default:
      return startStop
  }
}
