import type { Action, EndStop } from '$/types'

export const endStopReducer = (endStop: EndStop, action: Action): EndStop => {
  switch (action.type) {
    case 'UPDATE_END_STOP_PREFERENCE': {
      return {
        ...endStop,
        preference: action.preference,
      }
    }

    case 'UPDATE_END_STOP_ADDRESS': {
      return {
        ...endStop,
        ...action.endStop,
      }
    }

    case 'CLEAR_END_STOP_ADDRESS': {
      const { id, preference } = endStop

      return { preference, id }
    }

    default:
      return endStop
  }
}
