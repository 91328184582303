import { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

import colors from './colors'

export default createGlobalStyle`
  body {
    ${tw`
      antialiased
      m-0
      text-sm
      text-gray-900
      font-body
    `}
    background: ${colors.bodyBackground};
    -webkit-print-color-adjust:exact;
  }

  h1, h2, h3, h4, h5, h6 {
    ${tw`
      font-heading
      font-bold
    `}
  }

  h4+p, p+p {
    ${tw`
      mt-4
      mb-10
    `}
  }

  a {
    color: ${colors.dark['50']};
    text-decoration: none;
  }

  @media print {
    .BeaconFabButtonFrame {
      ${tw`
        hidden
      `}
    }
  }
`
