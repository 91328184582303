import React from 'react'
import { useIntl } from 'react-intl'

import { AuthInput, InputMessage } from './Auth.styled'

interface InputWithValidationProps {
  value: string
  type: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholderMessage: string
  errorMessage?: string
}

const InputWithValidation: React.FC<InputWithValidationProps> = ({
  value,
  type,
  onChange,
  placeholderMessage,
  errorMessage,
}) => {
  const showInputMessage = errorMessage && value !== ''

  return (
    <>
      <AuthInput
        type={type}
        name={type}
        value={value}
        placeholder={useIntl().formatMessage({ id: placeholderMessage })}
        onChange={onChange}
      />
      {showInputMessage && <InputMessage>{errorMessage}</InputMessage>}
    </>
  )
}

export default InputWithValidation
