/* eslint-disable complexity */

import type { Action, Stops, Stop } from '$/types'
import { buildFormattedAddress } from '$/utils/stops/address'
import { preSort, sort } from '$/utils/stops/sort'

import { getDefaultStopData } from '../api/defaultData'

import type {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@firebase/firestore-types'

export const stopsReducer = (stops: Stops, action: Action): Stops => {
  switch (action.type) {
    case 'ADD_STOP': {
      const { id } = action.routeRef.collection('stops').doc()

      // An attempt means user has empty stops and is trying
      // to add a new one. We only add an empty stop when no
      // empty stop is available. We still need to send a message
      // to the UI so the proper stop input can receive focus.
      if (!action.attempt) stops.set(id, null)

      return copyAndSort(stops, action.numberOfDrivers)
    }

    case 'ADD_STOPS_BATCH': {
      const { newStops } = action

      for (const [id, stop] of newStops.entries()) {
        stops.set(id, stop)
      }

      return copyAndSort(stops, action.numberOfDrivers)
    }

    case 'EDIT_STOPS_BATCH': {
      stops = action.isAppInitialized ? stops : new Map()
      applyChangesToStops(stops, action.changes)

      return copyAndSort(stops, action.numberOfDrivers)
    }

    case 'DELETE_STOP': {
      stops.delete(action.id)

      return copyAndSort(stops, action.numberOfDrivers)
    }

    case 'DELETE_ALL_STOPS': {
      stops.clear()

      return new Map()
    }

    case 'RERENDER_STOPS': {
      return copyAndSort(stops, action.numberOfDrivers)
    }

    default:
      return stops
  }
}

const applyChangesToStops = (stops: Stops, changes: QuerySnapshot) => {
  changes.forEach((change: DocumentData) => {
    const stop = parseStop(change.doc)

    if (change.type === 'added') {
      stops.set(stop.id!, stop)
    } else if (change.type === 'modified') {
      // Separate out in prep for optimizations
      stops.set(stop.id!, stop)
    } else if (change.type === 'removed') {
      stops.delete(stop.id!)
    }
  })
}

const parseStop = (stopDoc: QueryDocumentSnapshot): Stop => {
  const stop = {
    ...getDefaultStopData(),
    ...stopDoc.data(),
    ...{ id: stopDoc.id },
  }

  stop.formattedAddress = buildFormattedAddress(stop)

  return stop
}

const copyAndSort = (stops: Stops, numberOfDrivers: number): Stops => {
  const preSortedStops = preSort(stops)

  buildDriverNumbers(preSortedStops, numberOfDrivers)
  const sortedStops = sort(stops)

  buildStopNumbers(sortedStops)

  return new Map(sortedStops)
}

const buildDriverNumbers = (
  stops: Array<[string, Stop | null]>,
  numberOfDrivers: number,
) => {
  const maxDrivers = numberOfDrivers
  const drivers = new Map<string, number>()

  stops.forEach(([, stop]) => {
    if (!stop) return

    const driver = stop?.driver

    if (stop?.driver !== '') {
      if (!drivers.has(driver)) {
        if (drivers.size < maxDrivers) {
          stop.localData.driverNumber = drivers.size
          drivers.set(driver, drivers.size)
        } else {
          stop.localData.driverNumber = -1
        }
      } else {
        stop.localData.driverNumber = drivers.get(driver)!
      }
    } else {
      stop.localData.driverNumber = -1
    }
  })
}

const buildStopNumbers = (stops: Array<[string, Stop | null]>) => {
  let currentDriver = 0
  let stopNumber = -1

  stops.forEach(([, stop]) => {
    if (!stop) return
    if (stop.localData.driverNumber === currentDriver) {
      stopNumber++
    } else {
      stopNumber = 0
      currentDriver = stop.localData.driverNumber
    }

    stop.localData.stopNumber = stopNumber
  })
}
