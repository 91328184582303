import type { Subscription, Action } from '$/types'

export const subscriptionReducer = (
  subscription: Subscription,
  action: Action,
): Subscription => {
  switch (action.type) {
    case 'UPDATE_SUBSCRIPTION':
      return action.subscription

    default:
      return subscription
  }
}
